
export default {
    name: 'Tab',
    data() {
        return {
            active: '',
            list: []
        }
    },
    created() {
        this.initial()
        this.setActive()
    },
    methods: {
        // tab change
        changeTab(e) {
            if(e == 'only') {
                window.location.href = 'https://m.yfn.com/community/videolist'
                return
            }
            this.$router.push({
                path: e
            })
        },
        // 设置tab状态
        setActive() {
            let currentPath = this.$router.history.current.name.toLowerCase()
            this.active = currentPath || 'home'
            this.active == 'index' && (this.active = 'home') 
        },
        // 初始化
        initial() {
            this.list = [{
                name: '/',
                value: 'YFN',
                class: 'Tab-home',
                activeClass: 'Tab-home--active'
            }, {
                name: '/Category-Seo',
                value: 'Category',
                class: 'Tab-category',
                activeClass: 'Tab-category--active'
            }, {
                name: 'only',
                value: 'Only',
                class: 'Tab-only',
                activeClass: 'Tab-only--active'
            }, {
                name: '/shoppingbag',
                value: 'Bag',
                class: 'Tab-bag',
                activeClass: 'Tab-bag--active'
            }, {
                name: '/mine',
                value: 'Me',
                class: 'Tab-mine',
                activeClass: 'Tab-mine--active'
            }]
        }
    },
}
