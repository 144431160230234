
export default {
    name: 'YfnButton',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        color: {
            type: String,
            default: () => {
                return '#96BD2C'
            }
        },
        plain: Boolean,
        width: String,
        height: String,
        textColor:String,
        border:String
    },
    data() {
        return {}
    },
    computed: {
        'borderColor'() {
            return this.plain && this.color.includes('333') ? '#cccccc' : this.color
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
